<template>
<div style="max-width: 800px">

  <div class="card-box-full">
    <div class="card-box-header">
      Conflicting Reputations
    </div>

    <!-- ----------------------------------------------------------------- -->
    <div v-if="errorMessage">
      <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
    </div>

    <div v-if="isRefreshing">
      <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 10px">
        <span class="sr-only"></span>
      </div>
    </div>

    <div v-else>
      <table style="margin-top: 10px">
        <tr v-for="message in messages">
          <td align="center" valign="top">
            <div v-if="message.positive_reputation" class="badge badge-pill badge-success" style="margin-top: 9px">Positive</div>
            <div v-else class="badge badge-pill badge-danger" style="margin-top: 9px">Negative</div>
            <div style="font-size: 10px; font-weight: 500; margin-top: 0px">reputation</div>
          </td>
          <td>
            <div v-if="message.profile.last_message_blocked" class="alert alert-danger" style="padding: 5px; cursor: pointer" @click="clickedFingerprint(message.fingerprint)">
              <div style="border-bottom: 1px solid #e35557; padding-bottom: 5px; margin-bottom: 5px">
                <span class="badge badge-pill badge-danger">Blocked</span>
              </div>
              {{ message.message }}
            </div>
            <div v-else class="alert alert-success" style="padding: 5px; cursor: pointer" @click="clickedFingerprint(message.fingerprint)">
              <div style="border-bottom: 1px solid #37b760; padding-bottom: 5px; margin-bottom: 5px">
                <span class="badge badge-pill badge-success">Allowed</span>
              </div>
              {{ message.message }}
            </div>

          </td>
        </tr>
      </table>
    </div>

  </div>

</div>
</template>

<script>
export default {
  name: 'Conflicting-Reputations',
  data() {
    return {
      errorMessage: null,
      isRefreshing: false,
      messages: []
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedFingerprint(fingerprint) {
      var url = "http://admin.robocalls.ai/#/sms-profile/fingerprint/" + fingerprint;
      window.open(url, '_blank');
    },
    //--------------------------------------------------------------------------
    displayMessages(messages, profiles) {

      // Put all the profiles into a map
      var profilesMap = {}
      profiles.forEach(profile => {
          if (profile.last_message_blocked && profile.total_blocked == 0) {
            return;
          } else if (!profile.last_message_blocked && profile.total_allowed == 0) {
            return;
          } else if (profile.last_message_blocked == null) {
            return;
          } else {
            profilesMap[profile.fingerprint] = profile;
          }
        });

      console.log(JSON.stringify(profilesMap, null, 2));

      // Generate the list of conflicting messages
      var conflicts = [];
      messages.forEach(message => {
        if (!Object.hasOwn(profilesMap, message.fingerprint)) {
          return;
        } else {
          message.profile = profilesMap[message.fingerprint];
        }
        if (message.positive_reputation == message.profile.last_message_blocked) {
          conflicts.push(message);
        }
      });

      this.messages = conflicts;
    },
    //--------------------------------------------------------------------------
    processHistoricalMessages(response) {
      // Make sure we received messages
      if (!response || !response.messages) {
        this.errorMessage = "No messages were returned";
        return;
      }

      // Gather all the fingerprints
      var fingerprints = [];
      for (var x = 0; x < response.messages.length; x++) {
        var message = response.messages[x];
        if (message.fingerprint != null && message.fingerprint.length > 0) {
          fingerprints.push(message.fingerprint);
        }
      }

      // Make sure we have fingerprints
      if (fingerprints.length == 0) {
        this.errorMessage = "No messages have a fingerprint";
        return;
      }

      // Fetch the fingerprint profiles
      this.isRefreshing = true;
      var body = JSON.stringify({
        fingerprints: fingerprints
      });
      var that = this;
      var method = "POST";
      var url = "https://sms-profile.platform.robocalls.ai/fingerprint/profiles";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.displayMessages(response.messages, result.profiles);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server: " + err;
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })

    },
    //--------------------------------------------------------------------------
    refresh() {
      if (this.isRefreshing) {
        return;
      } else {
        this.errorMessage = null;
        this.isRefreshing = true;
        this.negative = [];
        this.positive = [];
      }

      // Fetch the historical messages
      var that = this;
      var method = "GET";
      var url = "https://sms-reputation.platform.robocalls.ai/historical-messages";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the response
            try {
              var result = JSON.parse(body);
              that.processHistoricalMessages(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>
